import React from 'react';

import burger from '../images/icons/burger.svg';

const Header: React.FC = () => {
  return (
    <header className="header">
      <div className="container header__container">
        <a href="/">
          <div className="logo header__logo">ozon страхование</div>
        </a>
      </div>
      <div className="header__line" />
    </header>
  );
};

export default Header;
