import React from "react"

import ambulanceSvg from '../images/icons/ambulance.svg';
import hospitalizationSvg from '../images/icons/hospitalization.svg';
import diagnosisSvg from '../images/icons/diagnosis.svg';

const Services: React.FC = () => {
    return (
        <section className="services__section">
            <div className="container services__container">
                <h2 className="title advantages__title">ДМС поможет, когда нужны  </h2>
                <div className="services__large-cells">
                    <div className="block services__large-cell">
                        <div className="services__large-image services__large-image-dentistry">
                        </div>
                        <div className="services__text-block">
                            <h3 className="services__text-block-title">Амбулаторное<br />лечение</h3>
                            <p className="services__text-block-subtitle">
                                Приём у&nbsp;специалиста в&nbsp;клинике и&nbsp;вызов врача на&nbsp;дом
                            </p>
                        </div>
                    </div>
                    <div className="block services__large-cell">
                        <div className="services__large-image services__large-image-outpatient">
                        </div>
                        <div className="services__text-block">
                            <h3 className="services__text-block-title">Стоматология</h3>
                            <p className="services__text-block-subtitle">Профилактика, хирургия и&nbsp;лечение острой зубной боли</p>
                        </div>
                    </div>
                </div>
                <div className="services__cells cell-3f">
                    <div className="block services__cell">
                        <div className="services__image">
                            <img src={ambulanceSvg} loading="lazy" alt="icon" />
                        </div>
                        <div className="services__text-block">
                            <h3 className="title">Скорая помощь</h3>
                            <p className="subtitle">Выезд бригады, осмотр и&nbsp;транспортировка в больницу</p>
                        </div>
                    </div>
                    <div className="block services__cell">
                        <div className="services__image">
                            <img src={hospitalizationSvg} loading="lazy" alt="icon" />
                        </div>
                        <div className="services__text-block">
                            <h3 className="title">Госпитализация</h3>
                            <p className="subtitle">Прохождение экстренного и&nbsp;планового лечения</p>
                        </div>
                    </div>
                    <div className="block services__cell">
                        <div className="services__image">
                            <img src={diagnosisSvg} loading="lazy" alt="icon" />
                        </div>
                        <div className="services__text-block">
                            <h3 className="title">Диагностика</h3>
                            <p className="subtitle">Широкий спектр исследований и&nbsp;анализов</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Services